import React from 'react';
import { shape, string } from 'prop-types';

import ChevronRight from '@andes/icons/ChevronRight20';

const namespace = 'ui-search-segment';

const Header = ({ header, viewMore }) => {
  const { title } = header;
  const { link } = viewMore;

  return (
    <a href={link} className={`${namespace}__header`}>
      <h2 className={`${namespace}__title`} id={title}>
        {title}
      </h2>
      <ChevronRight />
    </a>
  );
};

Header.propTypes = {
  header: shape({
    title: string,
  }),
  viewMore: shape({
    link: string,
  }),
};

export default Header;
