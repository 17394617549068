import React from 'react';
import { arrayOf, shape, string } from 'prop-types';

import { Button } from '@andes/button';

const namespace = 'ui-search-segment-top-keywords';

const SegmentTopKeywords = ({ keywords, title }) => {
  const handleClick = (id) => {
    document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className={namespace}>
      <h2 className={`${namespace}__header`}>{title}</h2>
      <ul className={`${namespace}__quick-access`}>
        {keywords.map(({ label }) => (
          <li key={label}>
            <Button hierarchy="quiet" onClick={() => handleClick(label)} size="medium">
              {label}
            </Button>
          </li>
        ))}
      </ul>
    </div>
  );
};

SegmentTopKeywords.propTypes = {
  keywords: arrayOf(
    shape({
      label: string,
    }),
  ),
  title: string,
};

export default SegmentTopKeywords;
