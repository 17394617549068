import React from 'react';
import { shape, string } from 'prop-types';

import classnames from 'classnames';
import { Button } from '@andes/button';

const namespace = 'ui-search-segment';

const Footer = ({ viewMore }) => {
  const { call_to_action, link } = viewMore;

  return (
    <div className={classnames(`${namespace}__footer`)}>
      <Button size="medium" href={link} hierarchy="quiet">
        {call_to_action}
      </Button>
    </div>
  );
};

Footer.propTypes = {
  viewMore: shape({
    call_to_action: string,
    link: string,
    position: string,
  }),
};

export default Footer;
