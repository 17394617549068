import React from 'react';
import { arrayOf, shape, string } from 'prop-types';

import classnames from 'classnames';
import { Card as CardContainer } from '@andes/card';

import OneColumnItemLayoutPolycard from '../layout/columns/one-column-item-polycard';
import Header from './components/header';
import Footer from './components/footer';

const namespace = 'ui-search-segment';

const Segment = ({ segment, layout }) => {
  const { header, results, view_more } = segment;

  return (
    <div className={classnames(`${namespace}__container`)}>
      <CardContainer>
        <Header header={header} viewMore={view_more} />
        <ul>
          {results.map((result, i) => (
            <li key={result.polycard.unique_id} className={classnames(`${namespace}__result`)}>
              <OneColumnItemLayoutPolycard item={result} index={i} layout={layout} />
            </li>
          ))}
        </ul>
        {view_more?.position === 'footer' && <Footer viewMore={view_more} />}
      </CardContainer>
    </div>
  );
};

Segment.propTypes = {
  layout: string,
  segment: shape({
    header: shape({
      title: string,
    }),
    view_more: shape({
      call_to_action: string,
      link: string,
      position: string,
    }),
    layout: string,
    results: arrayOf(shape({})),
  }),
};

export default Segment;
